<template>
  <MainLayout>
    <div class="row cl-090D2E">
      <h1 class="mb-2 mb-md-3">
        <strong>Your personal details</strong>
      </h1>
      <p>
        Please check the completeness of the data before saving it. Especially
        the postal address (where the contract will be sent) and the telephone
        number (you must enter it in international format: +41 44 5152593, for
        example).
      </p>
      <p>
        We will pass these details on to the bank to open an investment account
        for you (brokerage account). All data transfers between you,
        Simplewealth and the bank are protected with 128-bit encryption and a
        PGP key.
      </p>
    </div>

    <div
        class="row mt-5"
        v-if="aboutYou"
    >
      <div class="col-12 mt-4 d-flex flex-wrap">
        <div class="col-6 pe-2">
          <div class="sw-label">
            <div class="sw-label-text">
              Gender
            </div>
            <div class="d-flex swl-content">
              <div class="sw-radio me-5">
                <input
                    type="radio"
                    id="ayGenderM"
                    checked
                    name="marital-status-radio"
                    v-model="aboutYou.gender"
                    value="M"
                >
                <label for="ayGenderM">Male</label>
              </div>
              <div class="sw-radio">
                <input
                    type="radio"
                    id="ayGenderF"
                    name="marital-status-radio"
                    v-model="aboutYou.gender"
                    value="F"
                >
                <label for="ayGenderF">Female</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 ps-2">
          <div
              id="ayDOB"
              class="sw-label"
              :class="{ 'form-error': v$.aboutYou.dob.$error }"
          >
            <div class="sw-label-text">
              Date of birth
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <!-- <input
                  type="text"
                  placeholder="01-12-1970"
                  v-maska="'##-##-####'"
                  v-model="aboutYou.dob"
                />-->
                <Datepicker
                    v-model="aboutYou.dob"
                    :placeholder="aboutYou.dob"
                    format="dd-MM-yyyy"
                    autoApply
                    preventMinMaxNavigation
                    noToday
                    :startDate="(typeof aboutYou.dob === 'object' ? new Date(aboutYou.dob) : new Date(aboutYou.dob.slice(6,10), aboutYou.dob.slice(3,5)-1))"
                    :minDate="(new Date(new Date().setFullYear(new Date().getFullYear() - 70)))"
                    :maxDate="(new Date(new Date().setFullYear(new Date().getFullYear() -18)))"
                    :flow="['year', 'month', 'calendar']"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 pe-2">
          <div
              class="sw-label"
              :class="{ 'form-error': v$.aboutYou.first.$error }"
          >
            <div class="sw-label-text">
              First name
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayFName"
                    type="text"
                    placeholder="Your first name"
                    v-model="aboutYou.first"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 ps-2">
          <div
              class="sw-label"
              :class="{ 'form-error': v$.aboutYou.last.$error }"
          >
            <div class="sw-label-text">
              Last name
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayLName"
                    type="text"
                    placeholder="Your last name"
                    v-model="aboutYou.last"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="row mt-5"
        v-if="aboutYou"
    >
      <div class="col-12 mb-4">
        <h3
            class="cl-090D2E"
            id="addr"
        >
          Your residential address
        </h3>
        <div class="d-flex mt-4 flex-wrap">
          <div
              id="ayCountry"
              class="sw-label col-6 mb-3 pe-2"
              :class="{ 'form-error': v$.aboutYou.residenceAddr.country.$error }"
          >
            <div class="sw-label-text">
              Country
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="allowedCountries"
                  class="sw-select w-100"
                  v-model="aboutYou.residenceAddr.country"
                  searchable
                  clear-on-select
              />
            </div>
          </div>

          <div
              id="ayState"
              class="sw-label col-6 mb-3 ps-2"
              :class="{ 'form-error': v$.aboutYou.residenceAddr.state.$error }"
          >
            <div class="sw-label-text">
              State/province
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="
                  aboutYou.residenceAddr.country
                    ? countryList[aboutYou.residenceAddr.country]
                    : []
                "
                  class="sw-select w-100"
                  v-model="aboutYou.residenceAddr.state"
                  searchable
                  clear-on-select
              />
            </div>
          </div>

          <div
              class="col-6 mt-3 pe-2 sw-label"
              :class="{ 'form-error': v$.aboutYou.residenceAddr.city.$error }"
          >
            <div class="sw-label-text">
              City
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayCity"
                    type="text"
                    placeholder="Enter address"
                    v-model="aboutYou.residenceAddr.city"
                >
              </div>
            </div>
          </div>

          <div
              class="col-6 mt-3 ps-2 sw-label"
              :class="{
              'form-error': v$.aboutYou.residenceAddr.postalCode.$error,
            }"
          >
            <div class="sw-label-text">
              Postal code
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayZIP"
                    type="text"
                    placeholder="Enter postal code"
                    v-model="aboutYou.residenceAddr.postalCode"
                >
              </div>
            </div>
          </div>

          <div
              class="col-12 mt-3 sw-label"
              :class="{ 'form-error': v$.aboutYou.residenceAddr.addr.$error }"
          >
            <div class="sw-label-text">
              Your full address
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayAddr"
                    type="text"
                    placeholder="Enter street name, house number, apt"
                    v-model="aboutYou.residenceAddr.addr"
                >
              </div>
            </div>
          </div>

          <div
              id="ayPhoneCountry"
              class="sw-label col-6 mt-3 pe-2"
              :class="{
              'form-error': v$.aboutYou.residenceAddr.phoneCountry.$error,
            }"
          >
            <div class="sw-label-text">
              Phone country
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="countries"
                  class="sw-select w-100"
                  v-model="aboutYou.residenceAddr.phoneCountry"
                  searchable
                  clear-on-select
              />
            </div>
          </div>

          <div
              class="col-6 mt-3 pe-2 sw-label"
              :class="{ 'form-error': phoneError }"
          >
            <div class="sw-label-text">
              Mobile phone number
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayPhone"
                    type="text"
                    :placeholder="
                    aboutYou.residenceAddr.phoneCountry &&
                      $store.state.phoneCodes[aboutYou.residenceAddr.phoneCountry] !==
                      undefined
                      ? $store.state.phoneCodes[aboutYou.residenceAddr.phoneCountry]
                      : '+'
                  "
                    :value="aboutYou.residenceAddr.phone"
                    @keyup="validatePhoneNumber"
                >
                <span class="sw-label-text">{{ phoneError }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          id="ayDiffMailAddr"
          class="col-12 mt-4"
      >
        <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{ 'bordered-wrapper': !aboutYou.differentMailingAddr }"
        >
          <div class="col-10">
            I have a different mailing address
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                  type="checkbox"
                  id="ayDiffMailAddrSwitch"
                  name="switch-1"
                  v-model="aboutYou.differentMailingAddr"
              >
              <label for="ayDiffMailAddrSwitch"/>
            </div>
          </div>
        </div>

        <div
            class="col-12 d-flex flex-wrap mt-4"
            v-if="aboutYou.differentMailingAddr"
        >
          <h3 class="cl-090D2E col-12 mb-5">
            Your mailing address
          </h3>

          <div
              id="ayDiffMailAddrCountry"
              class="sw-label col-6 mb-3 pe-2"
              :class="{ 'form-error': v$.aboutYou.mailing_addr.country.$error }"
          >
            <div class="sw-label-text">
              Country
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="countries"
                  class="sw-select w-100"
                  v-model="aboutYou.mailing_addr.country"
                  searchable
                  clear-on-select
              />
            </div>
          </div>

          <div
              id="ayDiffMailAddrState"
              class="sw-label col-6 mb-3 ps-2"
              :class="{ 'form-error': v$.aboutYou.mailing_addr.state.$error }"
          >
            <div class="sw-label-text">
              State/province
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="
                  aboutYou.mailing_addr.country
                    ? countryList[aboutYou.mailing_addr.country]
                    : []
                "
                  class="sw-select w-100"
                  v-model="aboutYou.mailing_addr.state"
                  searchable
                  clear-on-select
              />
            </div>
          </div>

          <div
              class="col-6 mt-3 pe-2 sw-label"
              :class="{ 'form-error': v$.aboutYou.mailing_addr.city.$error }"
          >
            <div class="sw-label-text">
              City
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayDiffMailAddrCity"
                    type="text"
                    placeholder="Enter address"
                    v-model="aboutYou.mailing_addr.city"
                >
              </div>
            </div>
          </div>

          <div
              class="col-6 mt-3 ps-2 sw-label"
              :class="{
              'form-error': v$.aboutYou.mailing_addr.postal_code.$error,
            }"
          >
            <div class="sw-label-text">
              Postal code
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayDiffMailAddrZIP"
                    type="text"
                    placeholder="Enter postal code"
                    v-model="aboutYou.mailing_addr.postal_code"
                >
              </div>
            </div>
          </div>

          <div
              class="col-12 mt-3 sw-label bordered-wrapper pb-4"
              :class="{ 'form-error': v$.aboutYou.mailing_addr.addr.$error }"
          >
            <div class="sw-label-text">
              Your full address
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayDiffMailAddrFull"
                    type="text"
                    placeholder="Enter street name, house number, apt"
                    v-model="aboutYou.mailing_addr.addr"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="col-12 mt-4">
          <div class="d-flex align-items-center col-12 pb-4 bordered-wrapper">
            <div class="col-10">
              I am a citizen of the United States
            </div>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                    type="checkbox"
                    id="ayUS"
                    name="switch-us"
                    v-model="aboutYou.usCitizen"
                    @change="usCitizenChange"
                >
                <label for="ayUS"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex bordered-wrapper pb-4 flex-wrap">
          <div
              class="col-12 col-md-6 mt-4 pe-md-2 sw-label"
              :class="{ 'form-error': v$.aboutYou.passport_number.$error }"
          >
            <div class="sw-label-text">
              ID Document number
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayIDNum"
                    type="text"
                    placeholder="Enter passport number"
                    v-model="aboutYou.passport_number"
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-4 ps-md-2 sw-label">
            <div
                id="ayIDExp"
                class="sw-label"
                :class="{ 'form-error': v$.aboutYou.IdExpirationDate.$error }"
            >
              <div class="sw-label-text">
                ID Document expiration date
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield sw-datepicker w-100">
                  <!-- <input
                    type="text"
                    placeholder="01-12-1970"
                    v-maska="'##-##-####'"
                    v-model="aboutYou.IdExpirationDate"
                  />-->
                  <Datepicker
                      v-model="aboutYou.IdExpirationDate"
                      :placeholder="aboutYou.IdExpirationDate"
                      format="dd-MM-yyyy"
                      autoApply
                      preventMinMaxNavigation
                      noToday
                      :startDate="(typeof aboutYou.IdExpirationDate === 'object' ? new Date(aboutYou.IdExpirationDate) : new Date(aboutYou.IdExpirationDate.slice(6,10), aboutYou.IdExpirationDate.slice(3,5)-1))"
                      :minDate="(new Date())"
                      :maxDate="(new Date(new Date().setFullYear(new Date().getFullYear() + 100)))"
                      :flow="['year', 'month', 'calendar']"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
              class="col-12 col-md-6 mt-4 sw-label pe-md-2"
              :class="{ 'form-error': v$.aboutYou.tax_number.$error }"
          >
            <div
                class="sw-label-text"
                v-if="aboutYou.citizenship == 'United States'"
            >
              SSN
            </div>
            <div
                class="sw-label-text"
                v-else
            >
              Tax identification number
              <span
                  v-if="aboutYou.residenceAddr.country && !aboutYou.usCitizen"
              >({{ aboutYou.residenceAddr.country }})</span>
              <span v-if="aboutYou.residenceAddr.country && aboutYou.usCitizen">(USA)</span>
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                    id="ayTIN"
                    type="text"
                    placeholder="Enter tax number"
                    v-model="aboutYou.tax_number"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          class="col-12 mt-4"
          v-if="!aboutYou.tax_number && aboutYou.residenceAddr.country && !aboutYou.usCitizen"
      >
        <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{ 'bordered-wrapper': !aboutYou.dontHaveTax }"
            v-if="!aboutYou.tax_number"
        >
          <div class="col-10">
            I don’t have the Tax number of {{ aboutYou.residenceAddr.country }}
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                  type="checkbox"
                  id="ayNoTIN"
                  name="switch-2"
                  v-model="aboutYou.dontHaveTax"
              >
              <label for="ayNoTIN"/>
            </div>
          </div>
        </div>

        <div
            class="col-12 d-flex flex-wrap mt-4"
            v-if="aboutYou.dontHaveTax"
        >
          <div class="col-12 small cl-2D254D50">
            US Internal Revenue Service rules require the collection of a
            taxpayer identification number (or equivalent) (TIN) for all of your
            tax residencies. Failure to provide a TIN may require us to treat
            your account as undocumented for US tax purposes and impose
            exceptional withholding on income and proceeds paid to the account.
            Update your entry and provide your TIN above. If you do not have a
            TIN, provide an appropriate reason (one selection required):
          </div>

          <div class="bordered-wrapper py-3 col-12 mt-3">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                  type="radio"
                  id="1-radio"
                  name="example-radio"
                  v-model="aboutYou.dontHaveTaxDetails.option"
                  value="I have a US taxpayer identification number"
              >
              <label for="1-radio">
                I have a US taxpayer identification number and I will add it to
                the W8 tax form.
              </label>
            </div>
          </div>

          <div class="bordered-wrapper py-3 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                  type="radio"
                  id="2-radio"
                  name="example-radio"
                  v-model="aboutYou.dontHaveTaxDetails.option"
                  value="Country does not issue TINs to its residents"
              >
              <label for="2-radio">
                {{ aboutYou.residenceAddr.country }} does not issue TINs to its
                residents.
              </label>
            </div>
          </div>

          <div class="bordered-wrapper py-3 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                  type="radio"
                  id="3-radio"
                  name="example-radio"
                  v-model="aboutYou.dontHaveTaxDetails.option"
                  value="I am not legally required to obtain a TIN from country"
              >
              <label for="3-radio">
                I am not legally required to obtain a TIN from
                {{ aboutYou.residenceAddr.country }}
              </label>
            </div>
          </div>
          <div class="bordered-wrapper py-3 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                  type="radio"
                  id="4-radio"
                  name="example-radio"
                  value="TINs are issued, however, account holder is exempt from this requirement under the laws of country"
                  v-model="aboutYou.dontHaveTaxDetails.option"
              >
              <label for="4-radio">
                TINs are issued, however, account holder is exempt from this
                requirement under the laws of
                {{ aboutYou.residenceAddr.country }}
              </label>
            </div>
          </div>
          <div class="bordered-wrapper py-3 pb-5 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                  type="radio"
                  id="5-radio"
                  name="example-radio"
                  value="other"
                  v-model="aboutYou.dontHaveTaxDetails.option"
              >
              <label for="5-radio">Other</label>
            </div>
            <div
                class="col-12 mt-4 sw-label"
                v-if="aboutYou.dontHaveTaxDetails.option == 'other'"
                :class="{
                'form-error': v$.aboutYou.dontHaveTaxDetails.reason.$error,
              }"
            >
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Provide the reason here"
                      v-model="aboutYou.dontHaveTaxDetails.reason"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          class="col-12 mt-4"
          v-if="!aboutYou.tax_number && aboutYou.residenceAddr.country && !aboutYou.usCitizen"
      >
        <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{ 'bordered-wrapper': !aboutYou.differentTaxResidency }"
        >
          <div class="col-10">
            I have different Tax residency than
            {{ aboutYou.residenceAddr.country }}
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                  type="checkbox"
                  id="ayDiffTaxRes"
                  name="switch-3"
                  v-model="aboutYou.differentTaxResidency"
                  :disabled="aboutYou.usCitizen"
              >
              <label for="ayDiffTaxRes"/>
            </div>
          </div>
        </div>

        <div
            class="col-12 d-flex flex-wrap mt-4 bordered-wrapper"
            v-if="aboutYou.differentTaxResidency"
        >
          <div
              class="sw-label col-6 mb-3 pe-2 mt-3"
              :class="{
              'form-error':
                v$.aboutYou.differentTaxResidencyDetails.country.$error,
            }"
          >
            <div class="sw-label-text">
              Choose the country of your Tax residency
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="countries"
                  class="sw-select w-100"
                  v-model="aboutYou.differentTaxResidencyDetails.country"
                  searchable
                  clear-on-select
                  :disabled="aboutYou.usCitizen"
              />
            </div>
          </div>
          <div class="col-6 mt-3 ps-2">
            <div
                class="sw-label"
                :class="{
                'form-error':
                  v$.aboutYou.differentTaxResidencyDetails.tax_number.$error,
              }"
            >
              <div class="sw-label-text">
                Tax identification number
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                      type="text"
                      placeholder="Enter tax number"
                      v-model="aboutYou.differentTaxResidencyDetails.tax_number"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          id="ayDiffCitizenshipForm"
          class="col-12 mt-4"
          v-if="aboutYou.residenceAddr.country && !aboutYou.usCitizen"
      >
        <!--        <div-->
        <!--            class="d-flex align-items-center col-12 pb-4"-->
        <!--            :class="{ 'bordered-wrapper': !aboutYou.differentCitizenship }"-->
        <!--            v-if="!aboutYou.citizenship"-->
        <!--        >-->
        <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{ 'bordered-wrapper': !aboutYou.differentCitizenship }"
        >
          <div class="col-10">
            I have different citizenship than
            {{ aboutYou.residenceAddr.country }}
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                  type="checkbox"
                  id="ayDiffCitizenship"
                  name="switch-4"
                  v-model="aboutYou.differentCitizenship"
                  :disabled="aboutYou.usCitizen"
              >
              <label for="ayDiffCitizenship"/>
            </div>
          </div>
        </div>

        <div
            class="col-12 d-flex flex-wrap mt-4 bordered-wrapper"
            v-if="aboutYou.differentCitizenship"
        >
          <div
              class="sw-label col-6 mb-3"
              :class="{
              'form-error':
                v$.aboutYou.differentCitizenshipDetails.citizenship.$error,
            }"
          >
            <div class="sw-label-text">
              Choose your citizenship
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="countries"
                  class="sw-select w-100"
                  v-model="aboutYou.differentCitizenshipDetails.citizenship"
                  searchable
                  clear-on-select
                  @selected="differentCitizenshipChange"
                  :disabled="aboutYou.usCitizen"
              />
            </div>
          </div>
        </div>
      </div>
      <div
          id="ayDiffPOBForm"
          class="col-12 mt-4"
          v-if="aboutYou.residenceAddr.country"
      >
        <!--        <div-->
        <!--          class="d-flex align-items-center col-12 pb-4"-->
        <!--          :class="{ 'bordered-wrapper': !aboutYou.differentPlaceOfBirth }"-->
        <!--          v-if="!aboutYou.placeOfBirth"-->
        <!--        >-->
        <div
            class="d-flex align-items-center col-12 pb-4"
            :class="{ 'bordered-wrapper': !aboutYou.differentPlaceOfBirth }"
        >
          <div class="col-10">
            I was born outside of {{ aboutYou.residenceAddr.country }}
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                  type="checkbox"
                  id="switch-5"
                  name="switch-5"
                  v-model="aboutYou.differentPlaceOfBirth"
              >
              <label for="switch-5"/>
            </div>
          </div>
        </div>

        <div
            class="col-12 d-flex flex-wrap mt-4 bordered-wrapper"
            v-if="aboutYou.differentPlaceOfBirth"
        >
          <div
              class="sw-label col-6 mb-3"
              :class="{
              'form-error':
                v$.aboutYou.differentPlaceOfBirthDetails.placeOfBirth.$error,
            }"
          >
            <div class="sw-label-text">
              Choose the country where you were born
            </div>
            <div class="d-flex swl-content">
              <v-select
                  close-on-select
                  :options="countries"
                  class="sw-select w-100"
                  v-model="aboutYou.differentPlaceOfBirthDetails.placeOfBirth"
                  searchable
                  clear-on-select
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-auto mt-5">
        <div
            id="goToReview"
            @click="goToReview"
            class="btn btn-primary col-12 mt-3 px-4 button-padding"
        >
          Review and sign&nbsp;&nbsp; &rarr;
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<style>
.dp__theme_light {
  --dp-primary-color: #6610f2;
  --dp-success-color: #6610f2;
}

.dp__calendar_item,
.dp__overlay_col {
  outline: none;
}

[ref_key="timePickerRef"] {
  display: none;
}
</style>
<script>
/* eslint-disable */

import MainLayout from "@/layouts/MainLayout.vue";
import {mapActions, mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";
import {prepareWatchers} from "../services/prepareWatchers";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  isValidNumberForRegion,
  formatIncompletePhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js'
import api from '../services/api';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

var watchers = prepareWatchers("aboutYou");


export default {
  components: {
    MainLayout,
    Datepicker,
  },
  name: "AboutYou",
  data: () => {
    return {
      aboutYou: null,
      countryCodes: [],
      phoneError: null
    };
  },
  setup: () => ({v$: useVuelidate()}),
  validations: () => {
    return {
      aboutYou: {
        dob: {
          required,
        },
        first: {
          required,
        },
        last: {
          required,
        },
        residenceAddr: {
          addr: {
            required,
          },
          city: {
            required,
          },
          state: {
            required,
          },
          country: {
            required,
          },
          postalCode: {
            required,
          },
          phoneCountry: {
            required,
          },
          phone: {
            required,
          },
        },
        passport_number: {
          required,
        },
        IdExpirationDate: {
          required,
        },
        tax_number: requiredIf(() => !this.aboutYou.dontHaveTax),
        mailing_addr: {
          addr: requiredIf(() => this.aboutYou.differentMailingAddr),
          city: requiredIf(() => this.aboutYou.differentMailingAddr),
          state: requiredIf(() => this.aboutYou.differentMailingAddr),
          country: requiredIf(() => this.aboutYou.differentMailingAddr),
          postal_code: requiredIf(() => this.aboutYou.differentMailingAddr),
        },
        dontHaveTaxDetails: {
          option: requiredIf(() => this.aboutYou.dontHaveTax),
          reason: requiredIf(
                () =>
                    this.aboutYou.dontHaveTax &&
                    this.aboutYou.dontHaveTaxDetails.option == "other"
            ),
        },
        differentTaxResidencyDetails: {
          country:  requiredIf(() => this.aboutYou.differentTaxResidency),
          tax_number:requiredIf(() => this.aboutYou.differentTaxResidency),
        },
        differentCitizenshipDetails: {
          citizenship:requiredIf(() => this.aboutYou.differentCitizenship),
        },
        differentPlaceOfBirthDetails: {
          placeOfBirth: requiredIf(() => {
              return this.aboutYou.differentPlaceOfBirth;
            }),
        },
      },
    };
  },
  watch: {
    ...watchers,
    aboutYou: {
      handler(newValue) {
        this.setValue({
          key: "aboutYou",
          value: newValue,
        });
        if (
            this.aboutYou &&
            this.aboutYou.residenceAddr.country &&
            !this.aboutYou.residenceAddr.phoneCountry
        ) {
          this.aboutYou.residenceAddr.phoneCountry =
              this.aboutYou.residenceAddr.country;
        }

        if (!this.aboutYou.differentMailingAddr) {
          this.aboutYou.mailing_addr.country = null;
          this.aboutYou.mailing_addr.state = null;
        }

        if (
            this.aboutYou &&
            this.aboutYou.residenceAddr.country &&
            this.aboutYou.differentMailingAddr &&
            !this.aboutYou.mailing_addr.country
        ) {
          this.aboutYou.mailing_addr.country = this.aboutYou.residenceAddr.country;
        }

        if (
            this.aboutYou &&
            this.aboutYou.residenceAddr.state &&
            this.aboutYou.differentMailingAddr &&
            !this.aboutYou.mailing_addr.state
        ) {
          this.aboutYou.mailing_addr.state = this.aboutYou.residenceAddr.state;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["onbForm", "countryList"]),
    countries() {
      return Object.keys(this.countryList);
    },
    allowedCountries() {
      return Object.keys(this.$store.state.allowedCountries);
    },
    states() {
      if (!this.aboutYou) return [];
      if (this.aboutYou.residenceAddr.country) {
        return this.countryList[this.aboutYou.residenceAddr.country];
      }
      if (this.aboutYou.mailing_addr.country) {
        console.log("mailing_addr")
        return this.countryList[this.aboutYou.mailing_addr.country];
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "setValue",
      "fetchCountries",
      "turnOffBlur",
      "turnOnBlur",
      "fetchForm",
      "fetchPhoneCodes",
      "fetchAllowedCountries",
    ]),

    async fetchPhoneCountryCodes() {
      const res = await api.get("/NA2A3.json");
      this.countryCodes = res.data;
    },
    getCountryCode() {
      return this.countryCodes.name[this.aboutYou.residenceAddr.phoneCountry.toUpperCase()].alpha2;
    },
    validatePhoneNumber(e) {
      var phone = e.target.value;
      var countryCode = this.getCountryCode();
      var error = null;


      if (!isPossiblePhoneNumber(phone, countryCode) && !phone.startsWith("+")) {
        phone = "+" + phone;
      }
      if (!isPossiblePhoneNumber(phone, countryCode)) {
        error = "Phone number or country number is not correct";
      } else if (!isValidPhoneNumber(phone, countryCode)) {
        error = "Phone number or country number is not correct";
      } else if (!isValidNumberForRegion(phone, countryCode) && isValidPhoneNumber(phone, countryCode)) {
        error = null;
        var parsedNumber = parsePhoneNumber(phone);
        if (parsedNumber != undefined) {
          var country = this.countryCodes.alpha2[parsedNumber.country];
          if (this.countries.includes(country.name)) {
            this.aboutYou.residenceAddr.phoneCountry = country.name;
            console.log(this.aboutYou.residenceAddr.phoneCountry);
          }
        }
      }
      this.aboutYou.residenceAddr.phone = formatIncompletePhoneNumber(phone);
      this.phoneError = error;
    },
    differentCitizenshipChange(country) {
      if (country === "United States") {
        this.aboutYou.tax_number = "";
        this.aboutYou.usCitizen = true
        this.aboutYou.differentCitizenship = false
        this.aboutYou.differentTaxResidencyDetails.country = "United States";
      }
    },
    usCitizenChange() {
      if (this.aboutYou.usCitizen) {
        // this.aboutYou.differentTaxResidency = true
        // this.aboutYou.differentCitizenship = true
        // this.aboutYou.differentTaxResidencyDetails.country = "United States";
        // this.aboutYou.differentCitizenshipDetails.citizenship = "United States";
      }
    },
    validate() {
      this.v$.$validate();

      if (this.v$.$invalid) {
        console.log(this.v$);
        setTimeout(() => {
          document.getElementsByClassName("form-error")[0].scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
      return !this.v$.$invalid;
    },
    async goToReview() {
      if (this.validate()) {
        this.$store.state.form.aboutYou.completed = 1;
        await this.$store.dispatch("storeForm");
        this.$router.push({
          name: "ReviewAndSign",
          params: {id: this.$route.params.id},
        });
      }
    },
    scrollToElement(element) {
      setTimeout(() => {
        document.getElementById(element).scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    },
    startTimer() {
      let timeMax = 200;
      let time = timeMax;
      let vm = this;
      this.turnOnBlur();
      this.$store.state.timerText = "Give us a minute to prepare your data...";
      this.$store.state.timer = setInterval(() => {
        time = time - 0.05;
        if (time <= 0) {
          clearInterval(vm.$store.state.timer);
          vm.$store.state.timer = null;
          vm.turnOffBlur();
          vm.$store.state.timerPercent = 0;
        } else {
          vm.$store.state.timerPercent = 100 - (time * 100) / timeMax;
        }
      }, 50);

      let timer = setInterval(async () => {
        await vm.fetchForm();
        if (this.onbForm?.onlineIdentification?.blurAboutYou !== undefined) {
          if (this.onbForm?.onlineIdentification?.blurAboutYou) {
            clearInterval(timer);
            clearInterval(vm.$store.state.timer);
            vm.$store.state.timer = null;
            vm.turnOffBlur();
            vm.$store.state.timerPercent = 0;
          }
        }
      }, 5000);
    },
  },
  async mounted() {
    await this.fetchCountries();
    await this.fetchPhoneCodes();
    await this.fetchAllowedCountries();
    await this.fetchPhoneCountryCodes();
    this.aboutYou = this.onbForm.aboutYou;

    if (this.onbForm?.onlineIdentification?.IdExpirationDate !== undefined) {
      if (this.onbForm?.onlineIdentification?.IdExpirationDate) {
        this.aboutYou.IdExpirationDate = this.onbForm.onlineIdentification.IdExpirationDate
      }
    }

    if (this.onbForm?.onlineIdentification?.blurAboutYou !== undefined) {
      if (this.onbForm?.onlineIdentification?.blurAboutYou) {
        if (process.env.VUE_APP_SKIP_PASSBASE !== 'true') {
          this.startTimer();
        }
      }
    }
  },
};
</script>